














































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import Button from '@/app/ui/components/Button/index.vue'
import DropdownSelect from '@/app/ui/components/DropdownSelect/index.vue'
import MarketplaceTextInput from '../../components/MarketplaceTextInput/index.vue'
import MarketplaceDropdown from '../../components/MarketplaceDropdown/index.vue'
import POSController from '@/app/ui/controllers/POSController'
import WhiteSellerController from '@/app/ui/controllers/WhiteSellerController'
import { POS } from '@/domain/entities/POS'
import dayjs from 'dayjs'
import dayjsID from 'dayjs/locale/id'
import MarketplaceInputPhone from '../../components/MarketplaceInputPhone/index.vue'

interface OptionsNumber {
  label: string
  value: number
}

interface OptionsString  {
  label: string
  value: string
}

interface WhiteSeller {
  id: number
  name: string
  phone: string
  status: string
  partnerId: number
  partnerPOS: string
  role: string
  codHandling?: string
  createdAt: string
  createdBy: string
  updatedAt: string
  updatedBy: string
}

@Component({
  components: {
    Button,
    DropdownSelect,
    MarketplaceTextInput,
    MarketplaceDropdown,
    MarketplaceInputPhone
  },
})
export default class DetailSellerWhitelist extends Vue {
  POSController = POSController
  WhiteSellerController = WhiteSellerController
  statusOptions = [
    { label: 'Aktif', value: 'ACTIVE' },
    { label: 'Tidak Aktif', value: 'INACTIVE' },
  ]
  sellerRoleOptions = [
    { label: 'TOKOPEDIA', value: 'TOKOPEDIA' },
    { label: 'BUKALAPAK', value: 'BUKALAPAK' },
    { label: 'MERCHANT', value: 'MERCHANT' },
    { label: 'CLIENT', value: 'CLIENT' },
  ]
  codHandlingOptions = [
    {
      label: '-',
      value: ''
    },
    {
      label: 'specialcod',
      value: 'specialcod'
    },
    {
      label: 'standardcod',
      value: 'standardcod'
    }
  ]
  partnerPOSOptions = <OptionsNumber[]>([])
  form = {
    name: '',
    phone: '',
    status: this.statusOptions[0],
    agentId: [] as Record<string, any>,
    sellerRole: [] as Record<string, any>,
    codHandling: [] as Record<string, any>,
    createdAt: '',
    createdBy: '',
    updatedAt: '',
    updatedBy: '',
    phoneCode: '',
  }
  currentPartnerId = 0
  partnerPOSRequired = true
  paramsPartnerPOS = {
    page: 1,
    per_page: 100,
    search: '',
    sort_by: 'ASC',
  }

  created(): void {
    this.fetchData()
  }

  get showCODHandlingDropdown(): boolean {
    return this.form.sellerRole[0]?.value === 'CLIENT'
  }

  private async fetchData(posOnly?: boolean) {
    await POSController.getPOSData(this.paramsPartnerPOS)
    if (!posOnly) {
      WhiteSellerController.getDetail(this.$route.params.id)
    }
  }

  private onEdit() {
    const id = this.$route.params.id
    this.$router.push(`/marketplace/manage-whitelist/edit/${id}`)
  }

  private onPhoneCodeChange(phoneCode: string) {
    this.form.phoneCode = phoneCode
    this.form.phone = this.form.phone.replaceAll(this.form.phoneCode, '')
  }

  private maxLengthIndonesianPhoneNumber(): boolean {
    if (this.form.phoneCode === '+62') {
      return this.form.phone.length <= 13
    }
    return true
  }

  private minLengthIndosianPhoneNumber(): boolean {
    if (this.form.phoneCode === '+62') {
      return this.form.phone.length >= 9
    }
    return true
  }

  @Watch('POSController.posData')
  setPOSPartner(data: POS[]): void {
    const POSOptions = data.map(item => {
      return {
        label: `${item.name} [${item.code}]`,
        value: item.id
      }
    }) as OptionsNumber[]
    this.partnerPOSOptions = POSOptions
    this.form.agentId = <OptionsNumber>POSOptions.find(item => item.value === this.currentPartnerId)
  }

  @Watch('WhiteSellerController.dataWhiteSeller')
  updateForm(val: WhiteSeller): void {
    if (val) {
      // Set Partner POS
      this.paramsPartnerPOS = {
        ...this.paramsPartnerPOS,
        search: <string>val.partnerPOS
      }
      this.currentPartnerId = <number>val.partnerId
      this.fetchData(true)

      if (val.status === 'ACTIVE') {
        this.partnerPOSRequired = true
      } else {
        this.partnerPOSRequired = false
      }
      this.form = {
        name: <string>val.name,
        phone: val.phone,
        status: <OptionsString>this.statusOptions.find(item => item.value === val.status),
        agentId: this.partnerPOSOptions.filter(item => item.value === val.partnerId),
        sellerRole: this.sellerRoleOptions.filter(item => item.label === val.role),
        codHandling: <OptionsString>this.codHandlingOptions.find(item => item.value === val.codHandling),
        createdAt: val.createdAt ? dayjs(val.createdAt).locale(dayjsID).format('DD MMMM YYYY, HH:mm') : '-',
        createdBy: val.createdBy || '-',
        updatedAt: val.updatedAt ? dayjs(val.updatedAt).locale(dayjsID).format('DD MMMM YYYY, HH:mm') : '-',
        updatedBy: val.updatedBy || '-',
        phoneCode: '',
      }
    }
  }
}
